import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { CustomSourceKeyAssociciatedClients, PickListItem } from '../../../../models/Picklist';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import ClientService from '../../../../services/ClientService';
import PickListService from '../../../../services/PickListService';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { Option } from '../../../Option';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
import { EventSystem } from '../../../../events/EventSystem';
import { SupportedLanguage } from '../../../../types/Languages';

type SelectListActionData = {
  sourceType: string;
};

type SelectListActionProps = ActionBaseProps<Option<string, string | number>, SelectListActionData>;

const SelectListQuestion: FC<SelectListActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { sourceType } = data;
  const { onAnswer, readOnly } = useFormAction(props);
  const [options, setOptions] = useState<Option<string, string | number>[]>([]);
  const [pickListItems, setPickListItems] = useState<PickListItem[]>([]);
  const client = useRecoilValue(currentClientAtom);

  useEffect(() => {
    if (!sourceType) {
      return;
    }

    // Custom Entries
    if (sourceType === CustomSourceKeyAssociciatedClients) {
      if (!client) {
        setOptions([
          { id: 'A', text: 'Client A', value: 'Client A' },
          { id: 'B', text: 'Client B', value: 'Client B' },
          { id: 'C', text: 'Client C', value: 'Client C' },
        ]);
      } else {
        ClientService.getAffiliates(client.id).then((res) => {
          setOptions(
            res.data.map((client) => ({
              id: client.id,
              text: client.name,
              value: client.name,
            })),
          );
        });
      }
    }
    // Default
    else {
      PickListService.getPickList(sourceType).then((res) => {
        setPickListItems(res.data.items);
        createPickListOptions(res.data.items);
      });
    }
  }, [client, sourceType]);

  const createPickListOptions = (items: PickListItem[], code?: SupportedLanguage) => {
    setOptions(
      items.map((item) => {
        return {
          id: item.id,
          text: LanguageUtils.getTranslation('name', item.translations, code),
          value: LanguageUtils.getTranslation('name', item.translations, code),
        };
      }),
    );
  };

  useEffect(
    function langaugeChanged() {
      const handler = (code: SupportedLanguage) => {
        if (sourceType && sourceType !== CustomSourceKeyAssociciatedClients) {
          createPickListOptions(pickListItems, code);
        }
      };
      EventSystem.listen('language-changed', handler);
      return () => {
        EventSystem.stopListening('language-changed', handler);
      };
    },
    [pickListItems, sourceType],
  );

  const onClear = useCallback(() => {
    onAnswer(null);
  }, [onAnswer]);

  return (
    <div data-cy="select-list-action">
      <ActionTitleDescription required={required} {...data} />
      <div className="w-64">
        <DropdownSelect
          data-cy="select-list-dropdown"
          value={response}
          onChange={(response) => onAnswer(response)}
          options={options}
          placeholder={t('select-list.placeholder')}
          disabled={readOnly}
          onClear={onClear}
        />
      </div>
    </div>
  );
};

export default SelectListQuestion;
